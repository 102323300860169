@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Inter var';
    font-style: normal;
    font-weight: 100 900;
    font-display: swap;
    src: url('/fonts/inter-roman.latin.var.woff2') format('woff2');
    unicode-range: U+0000-007F, U+00A0-0100, U+0131, U+0152-0154, U+02BB-02BD,
      U+02C6, U+02DA, U+02DC, U+2000-200C, U+2010-2028, U+202F-2060, U+2074,
      U+20AC, U+2122, U+2191, U+2193, U+2212, U+FEFF;
  }

  @font-face {
    font-family: 'Roboto mono';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/roboto-mono-latin-regular.woff2') format('woff2');
  }

  h1 {
    @apply text-2xl;
  }
  

  /* html {
    @apply text-gray-darkest;
  } */
  
}

@layer components {
  .studio_button {

  }
}

@layer utilities {
  .studio-bg-main {
    background: #f4f5f9;
  }
  .studio-bg-dark {
    background: #183c9f;
  }
  .tx-dark {
    color: #292E3B
  }
  .tx-blue {
    color: #0F44D7
  }
  .border-blue {
    border-color: #0F44D7;
  }
  .border-blue-disabled {
    border-color: rgba(15, 68, 215, .7)
  }
  .tx-blue-light {
    color: #2657de;
  }
  .tx-light {
    color: rgba(70, 84, 122, 1);
  }
  .tx-extra-light {
    color: rgba(70, 84, 122, .5);
  }
  .tx-dark-blue {
    color: rgba(1, 40, 171, 1);
  }

/* preloader */
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #CCC;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
/* end preloader */

    .scrollbar-hidden::-webkit-scrollbar {
      display: none;
    }
}

html,
body,
body .studio_wrapper,
div#__next,
div#__next .studio_wrapper {
  height: 100%;
}
html, body { 
  margin: 0; padding: 0; 
  background: #f4f5f9;
}

