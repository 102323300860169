.modal_overlay_bg {
    background: #d1d5e0;
}
.modal_close_btn {
    width: 1.875rem;
    height: 1.875rem;
    background: linear-gradient(0deg, #0F44D7, #0F44D7), linear-gradient(0deg, #FFFFFF, #FFFFFF), #FFFFFF;
    border-radius: 5px 0px 0px 5px;
    position: absolute;
    left: -1.875rem;
}
.modal_content_footer {
    box-shadow: 0px -7px 23px rgba(195, 200, 212, 0.23);
    border-top-width: 1px; border-color: #f2f2f2;
}